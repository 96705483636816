*{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color';
    text-align: "center",
  }
  .rbc-toolbar{
      font-size: x-large;
      color: #303167;
  }

  .rbc-events-container {
    display: grid;
    grid-template-rows: repeat(48, 40px);
  }
  /* .rbc-events-container.week-grid{
    gap:2px;
    margin: 0 !important;
  } */
 
  .rbc-time-view .rbc-row {
    min-height: 40px;
}
  .rbc-day-slot .rbc-event {
    position: static;
    margin: 0;
    padding-right: 0;
  }
  
   .rbc-day-slot .rbc-time-slot {
    border: unset;
  }
  
  .rbc-day-slot  {
    overflow: auto;
    overflow-y: hidden;
    background: white;
  }

  .rbc-btn-group > button:last-child:not(:first-child) {
    width: 90px;
    border-radius: 0px 5px 5px 0px;
}
.rbc-btn-group > button:first-child:not(:last-child) {
    width: 90px;
    border-radius: 5px 0px 0px 5px;
}
  .rbc-timeslot-group:nth-child(4n+1),
  .rbc-timeslot-group:nth-child(4n+2),
  .rbc-timeslot-group:nth-child(4n+3),
  .rbc-timeslot-group:nth-child(4n+4)
   {  /* or 4n+1 */
   background-color: #fefefe;
      border-width:0px; 
   margin:0;
    padding:0;

  }

  .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    color: #303167;
    background-color: white;
    border-color: #303167;
  }
  .rbc-row-content{
    display: none;
  }
  .rbc-toolbar button:focus {
    color: #303167;
    background-color: white;
    border-color: #303167;
  }
  .rbc-toolbar button:hover {
    color: #303167;
    background-color: white;
    border-color: #303167;
  }
  
  .rbc-timeslot-group:nth-child(4n+1)
   { border-top: 1px solid #efefef;
  }
  .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    -webkit-box-shadow:  0 3px 5px white;
            box-shadow:  0 3px 5px white;

            background-color: #303167;
    border-color: #ffffff;
    border-radius: "25%";
    color:white
  }
  .rbc-header {
     display: flex;
     align-items: center;
     justify-content: center;
     border-bottom: none;
  }
  .rbc-current-time-indicator {
    visibility: hidden;
  }
  