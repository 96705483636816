.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-block {
  display: block !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-center{
  justify-content: center !important;
}

.p-relative {
  position: relative !important;
}

.h-100 {
  height: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.chartjs-render-monitor {
  margin: auto;
}

.align-center {
  text-align: center;
}
