@charset "UTF-8";
.pdfResult {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: black;
  line-height: initial;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 35px;
  width: 210mm;
  background-color: white;
  max-width: 210mm;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-weight: normal;
}

.pdfResult .header {
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pdfResult .header .logo img {
  height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
}

.pdfResult .header .title {
  margin-top: 25px;
  font-size: 35px;
  font-weight: bold;
}

.pdfResult .header .title .smaller {
  font-weight: normal;
  font-size: 23px;
  margin-left: 7px;
}

.pdfResult .card {
  width: 100%;
  border: 1px #E7E7E7 solid;
  background-color: white;
  -webkit-box-shadow: 0px 5px 2px -4px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 5px 2px -4px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 12px;
}

.pdfResult .card .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1px 4px 1px 3px;
  border-radius: 3px;
}

.pdfResult .card .title .icon {
  margin-right: 3px;
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.pdfResult .card .title.orange {
  color: #FF9900;
  background: rgba(255, 153, 0, 0.15);
}

.pdfResult .card .title.green {
  color: #00BA34;
  background-color: #E6F8EB;
}

.pdfResult .card .title.blue {
  color: #0383FF;
  background: rgba(3, 131, 255, 0.13);
}

.pdfResult .card .title.purple {
  color: #712CF9;
  background: rgba(113, 44, 249, 0.11);
}

.pdfResult .card .title.red {
  color: #ED0000;
  background: rgba(214, 0, 0, 0.1);
}

.pdfResult .card .title .bi.bi-clipboard-check-fill {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pdfResult .card .title .bi.bi-clipboard-check-fill:before {
  font-size: 0.9em;
}

.pdfResult .card .content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}

.pdfResult .card .content .content-elements {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}

.pdfResult .card .content.two-columns {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  row-gap: 20px;
}

.pdfResult .card .content .key {
  color: #585757;
}

.pdfResult .card .content .value {
  margin-left: 5px;
  color: black;
}

.pdfResult .card .content .dotted {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pdfResult .card .content .dotted .text {
  width: 100%;
}

.pdfResult .card .content .dotted:before {
  content: "•";
  font-family: 'Lato', sans-serif;
  margin-right: 9px;
  height: 100%;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #585757;
}

.pdfResult .card .content .text {
  width: 100%;
}

.pdfResult .card .content .comment {
  width: 100%;
  background: #F6F6F6;
  -webkit-box-shadow: 0px 2px 4px -3px rgba(0, 0, 0, 0.6);
          box-shadow: 0px 2px 4px -3px rgba(0, 0, 0, 0.6);
  border-radius: 0px 4px 4px 4px;
  padding: 13px 15px;
  margin-top: 9px;
  line-height: 26px;
  margin-bottom: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.pdfResult .card .content .comment.description {
  margin: 0;
}

.pdfResult .card .content .on {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: black;
}

.pdfResult .card .content .on:before {
  color: #00BA34;
  content: "\f272";
}

.pdfResult .card .content .off {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #585757;
}

.pdfResult .card .content .off:before {
  color: #FF0000;
  content: "\F62A";
}

.pdfResult .card .content .on:before, .pdfResult .card .content .off:before {
  display: inline-block;
  font-display: block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  margin-right: 3px;
  height: 100%;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  top: 2px;
}

.pdfResult .card .content.results {
  width: 100% !important;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 9fr 8fr;
      grid-template-columns: 9fr 8fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  -webkit-column-gap: 50px;
          column-gap: 50px;
}

.pdfResult .card .content .graph-container .graph-dot-title {
  font-size: 12px;
  padding: 11px 0px 7px 3px;
}

.pdfResult .card .content .graph-container .graph-dot-title::before {
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: black;
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.17), 0px 1px 3px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.17), 0px 1px 3px rgba(0, 0, 0, 0.16);
  position: relative;
  margin-right: 5px;
}

.pdfResult .card .content .graph-container .title {
  color: #585757;
  font-size: 1em;
  margin-bottom: 5px;
}

.pdfResult .card .content .graph-container .graph-frame {
  height: 250px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto;
  padding-top: 5px;
  padding-left: 5px;
}

.pdfResult .card .content .graph-container .graph-inner {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
  background: #F6F6F6;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: relative;
}

.pdfResult .card .content .graph-container .graph-inner .dividers {
  position: absolute;
  width: 100%;
  height: 100%;
}

.pdfResult .card .content .graph-container .graph-inner .dividers:before {
  content: "";
  background-color: #585757;
  height: 100%;
  width: 1px;
  display: block;
  position: absolute;
  left: 50%;
}

.pdfResult .card .content .graph-container .graph-inner .dividers:after {
  content: "";
  background-color: #585757;
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
}

.pdfResult .card .content .graph-container .graph-inner .colored-areas {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
}

.pdfResult .card .content .graph-container .graph-inner .dot {
  height: 13px;
  width: 13px;
  border-radius: 50px;
  background-color: black;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.17), 0px 1px 3px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.17), 0px 1px 3px rgba(0, 0, 0, 0.16);
  position: relative;
  top: 40%;
  left: 10%;
  z-index: 10;
}

.pdfResult .card .content .graph-container .right-numbers,
.pdfResult .card .content .graph-container .bottom-numbers {
  font-size: 12px;
}

.pdfResult .card .content .graph-container .right-numbers {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}

.pdfResult .card .content .graph-container .right-numbers > div:after {
  content: ".";
  margin-left: 5px;
  bottom: 25%;
  position: relative;
}

.pdfResult .card .content .graph-container .bottom-numbers {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 2;
  grid-row: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 5px;
  position: relative;
}

.pdfResult .card .content .graph-container .bottom-numbers > div:before {
  content: ".";
  position: relative;
  bottom: 100%;
  left: 50%;
}

.pdfResult .card .content .graph-container .notes-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 25px;
  margin-top: 20px;
  padding-left: 15px;
}

.pdfResult .card .content .graph-container .notes-container .note {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 38px auto;
      grid-template-columns: 38px auto;
  gap: 8px;
}

.pdfResult .card .content .graph-container .notes-container .note .area {
  border: 2px #585757 solid;
  border-radius: 9px;
  width: 80%;
  height: 18px;
  overflow: hidden;
  margin-top: 3px;
}

.pdfResult .card .content .graph-container .notes-container .note .text {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  color: #585757;
}
/* to enable the proccess assement must just remove display none and the symbol of  comment in other displays */
.pdfResult .card .content .boxes {
  display: none;
  margin-bottom: 15px;
  margin-right: 10px;
  /*display: -webkit-box;
  display: -ms-flexbox;
  display: flex;*/
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 28px;
}

.pdfResult .card .content .boxes .box {
  background: #FFFFFF;
  border: 1px solid #e7e7e7;
  background-color: #fdfdfd;
  border-radius: 12px;
  padding: 17px;
}

.pdfResult .card .content .boxes .box .title {
  font-size: 1em;
  margin-bottom: 18px;
  padding: 0;
}

.pdfResult .card .content .boxes .box .title .icon {
  font-size: 18px;
  margin-right: 9px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  border-radius: 13px;
  color: white;
  margin-top: 2px;
}

.pdfResult .card .content .boxes .box .title .icon .bi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pdfResult .card .content .boxes .box .title .icon.green {
  background-color: #00BA34;
}

.pdfResult .card .content .boxes .box .title .icon.blue {
  background-color: #3080EC;
}

.pdfResult .card .content .boxes .box .title .icon.orange {
  background-color: #FF9900;
}

.pdfResult .card .content .boxes .box .title .text {
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.pdfResult .card .content .boxes .box .title .text .smaller {
  display: block;
  font-size: 14px;
  color: #585757;
  margin-top: 3px;
}

.pdfResult .card .content .boxes .box > .text:not(:last-child) {
  margin-bottom: 11px;
}

.pdfResult .card .content .boxes .box > .text:not(:last-child) .key {
  line-height: 20px;
}

.pdfResult .card .content .boxes .box > .text.right {
  text-align: end;
  padding-top: 6px;
}

.center {
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.overflow-hidden {
  overflow: hidden;
}

.bar-container {
  -webkit-transform: rotate(35deg) scale(1.7);
          transform: rotate(35deg) scale(1.7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background: #F6F6F6;
}

.bar-container.orange .bar {
  background-color: rgba(255, 166, 0, 0.6);
  -webkit-box-shadow: 0 0 5px 0px rgba(255, 153, 0, 0.27);
          box-shadow: 0 0 5px 0px rgba(255, 153, 0, 0.27);
}

.bar-container.red .bar {
  background-color: #ff626297;
  -webkit-box-shadow: 0 0 5px 0px rgba(255, 0, 0, 0.27);
          box-shadow: 0 0 5px 0px rgba(255, 0, 0, 0.27);
}

.bar-container.green .bar {
  background-color: #47dd7293;
  -webkit-box-shadow: 0 0 5px 0px rgba(0, 255, 70, 0.27);
          box-shadow: 0 0 5px 0px rgba(0, 255, 70, 0.27);
}

.bar-container .bar {
  position: relative;
  height: 100%;
  width: 1px;
  background-color: black;
  top: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.right-numbers-title {
  font-size: 12px;
  padding-left: 5px;
}

.left-numbers-title {
  font-size: 12px;
  padding-left: 5px;
  text-align: right;
}

.bi-search {
  font-size: 15px;
}

.uppercase {
  text-transform: uppercase;
}

.text-dark {
  color: #585757;
}
/*# sourceMappingURL=style.css.map */