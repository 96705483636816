.error_link {

	font-style: italic;
	font-size: large;
	position: absolute;
	/* postulat de départ */
	top: 120%;
	left: 20%;
	/* à 50%/50% du parent référent */
	transform: translate(-50%, -40%);
}

.error-text {
	font-style: italic;
	font-size: large;
	position: absolute;
	/* postulat de départ */
	top: 100%;
	left: 20%;
	/* à 50%/50% du parent référent */
	transform: translate(-50%, -40%);

}

.text-error {

	position: absolute;
	/* postulat de départ */
	top: -40%;
	left: 13%;
	/* à 50%0/50% du parent référent */
	transform: translate(-50%, -40%);
	font-size: 10vh;
}


.error_page {

	background: url(../assets/logo-error-404.png);
	background-repeat: no-repeat;
	background-size: contain;
	height: 300px;
	width: 100%;
	position: absolute;
	/* postulat de départ */
	top: 40%;
	left: 80%;
	/* à 50%/50% du parent référent */
	transform: translate(-50%, -40%);
}