.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}
.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}
.flag-icon:before {
    content: "\00a0";
}
.flag-icon.flag-icon-squared {
    width: 1em;
}
.flag-icon-fr {
    background-image: url(../assets/flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
    background-image: url(../assets/flags/1x1/fr.svg);
}
.flag-icon-es {
    background-image: url(../assets/flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
    background-image: url(../assets/flags/1x1/es.svg);
}
.flag-icon-pl {
    background-image: url(../assets/flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
    background-image: url(../assets/flags/1x1/pl.svg);
}
.flag-icon-us {
    background-image: url(../assets/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
    background-image: url(../assets/flags/1x1/us.svg);
}
.flag-icon-de {
    background-image: url(../assets/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
    background-image: url(../assets/flags/1x1/de.svg);
}