#root {
  /* background-image: url("./background.png"); */
  background-size: auto;
  background-repeat: no-repeat;
  background-color: #fafafa;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}


*::-webkit-scrollbar {
  width: 0.8em;
}
 *::-webkit-scrollbar-thumb {
   outline: none;
   background-color: #D0D8FF;
 }
 *::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.0);
 }